export const environment = {
    production: true,
    version: '5.0.0',
    secretKey: '2b85908e-f0f6-4ccc-b0c8-ea1b40c80d4d',
    clientId: 'd5fa105f-3e83-41da-8891-ab0bf18878ef',
    apiKey: '8bd5fc50-1b73-4f14-bd8d-720ff45731ea',
    embed_domain: 'https://admin-retailinsight.flywheeldigital.com',
    salesforce: {
        url: 'https://ascential.lightning.force.com',
        validAccountIdBeginning: '0013z',
        validSubscriptionIdBeginning: 'a3E3z',
        validIntegrationContactIdBeginning: 'a3L3z'
      },
    clientName: 'ERI-UMS',
    apiUrl: 'https://api-retailinsight.flywheeldigital.com/ums2',
    portalUrl: 'https://retailinsight.flywheeldigital.com',
    contentfulAPIURL : 'https://api-retailinsight.flywheeldigital.com/contentful2/',
    amplify: {
        Auth: {
            region: 'us-west-2',
            userPoolId: 'us-west-2_mTuc8aShf',
            userPoolWebClientId: '7ddlals1j2ql1mitjqtan9bh9l',
            identityPoolId: 'us-west-2:c3f17dd5-a88f-4438-b2d8-3126e510b425',
            mandatorySignIn: false,
            authenticationFlowType: 'USER_PASSWORD_AUTH',
            cookieStorage: {
                domain: '.flywheeldigital.com',
                path: '/',
                expires: 365,
                sameSite: 'strict',
                secure: true
            }
        },
    },
    aws: {
        adminGroup: 'eri_admin_role',
        superUserGroup: 'eri_super_user_role'
    }
};
